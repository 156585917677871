import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { WidthContainer } from "../components/widthContainer"
import HeaderLogo from "../components/headerLogo"
import Hero from "../components/hero"
import List from "../projects/list"

const ProjectsPage = ({
  data: { site, contentfulHeader, contentfulFooter, contentfulProjectsPage },
  uri,
}) => {
  const { siteName, logo } = contentfulHeader
  const { siteMetadata } = site

  const {
    pageShortTitle,
    pageTitle,
    pageDescription: { pageDescription },
    pageImage,
    projectText,
    projectTitle,
    projectList,
  } = contentfulProjectsPage

  return (
    <Layout
      header={contentfulHeader}
      footer={contentfulFooter}
      pageMeta={{ pageTitle, pageDescription, pageImage, uri }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: siteName,
                item: `${siteMetadata.siteUrl}`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: pageShortTitle,
              },
            ],
          })}
        </script>
      </Helmet>
      <WidthContainer>
        <HeaderLogo logo={logo} hideMd />
      </WidthContainer>
      <Hero text={projectText} />
      <List projectTitle={projectTitle} projectList={projectList} />
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHeader {
      ...HeaderInformation
    }
    contentfulFooter {
      ...FooterInformation
    }
    contentfulProjectsPage {
      pageShortTitle
      pageTitle
      pageDescription {
        pageDescription
      }
      pageImage {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              width
              height
              src
            }
          }
        }
      }
      projectText {
        json
      }
      projectTitle
      projectList {
        projectTitle
        projectSubtitle
        slug
        projectText {
          projectText
        }
        projectImage {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 704) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
