import React from "react"
import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { IoIosArrowDown, IoIosArrowRoundForward } from "react-icons/io"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
// import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  padding-top: 2rem;
  padding-bottom: 5rem;
  color: #fff;

  background: linear-gradient(
    200deg,
    ${props => props.theme.colorPrimary} 0,
    ${props => props.theme.colorSecondary} 100%
  );
`

const TitleContainer = styled.div`
  text-align: center;
`

const Title = styled.h1`
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  font-weight: 400;
  font-size: 1rem;
`

const ArrowDownIcon = styled(IoIosArrowDown)`
  vertical-align: text-bottom;
  display: inline-block;
  margin-left: 0.5rem;
`

const Projects = styled.div`
  margin-top: 3rem;
`

const ProjectContainer = styled.div`
  margin-top: 5rem;

  &:first-child {
    margin-top: 3rem;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    &:nth-child(even) {
      flex-direction: row;
    }
  }
`

const ProjectImage = styled(Img)`
  flex: 7;
`

const ProjectTitle = styled.h2`
  margin: 1.5rem 0 1.5rem;
  font-size: 2.25rem;
  line-height: 1.4;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 4rem;
    background: #fff;
    height: 5px;
    bottom: -0.8rem;
  }

  @media (min-width: 768px) {
    margin: 0 0 1.5rem;
  }
`
const ProjectSubtitle = styled.h3`
  margin: 1rem 0 0;
  font-size: 1.75rem;
  font-weight: 500;
`
const ProjectText = styled.div`
  margin: 0;
`

const ContentContainer = styled.div`
  flex: 6;
  padding-left: 1rem;

  @media screen and (min-width: 576px) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
`

const LinkButton = styled(Link)`
  margin-top: 1rem;
  display: inline-block;
  border: 2px solid #fff;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding: 0.5rem 3.5rem 0.5rem 1.5rem;
  font-weight: 500;
`

const LinkIcon = styled(IoIosArrowRoundForward)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`

const List = ({ projectTitle, projectList }) => {
  return (
    <Container>
      <WidthContainer>
        <TitleContainer>
          <Title>{projectTitle}</Title>
          <ArrowDownIcon size={"1.125rem"} />
        </TitleContainer>
        <Projects>
          {projectList.map(
            (
              {
                projectImage,
                projectTitle,
                projectSubtitle,
                projectText: { projectText },
                slug,
              },
              index
            ) => {
              return (
                <ProjectContainer key={index}>
                  <ProjectImage
                    alt={projectImage.description}
                    fluid={projectImage.localFile.childImageSharp.fluid}
                  />
                  <ContentContainer>
                    <ProjectTitle>{projectTitle}</ProjectTitle>
                    <ProjectSubtitle>{projectSubtitle}</ProjectSubtitle>
                    <ProjectText>{projectText}</ProjectText>
                    <LinkButton to={`/projects/${slug}/`}>
                      Learn more
                      <LinkIcon size={"2rem"} />
                    </LinkButton>
                  </ContentContainer>
                </ProjectContainer>
              )
            }
          )}
        </Projects>
      </WidthContainer>
    </Container>
  )
}
export default List
